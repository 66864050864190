/* eslint-disable no-undef */
<script>
import Layout from '@/views/layouts/main'
// import PageHeader from '@/components/page-header'
// import router from '../../router/routes.js'
/**
 * Starter page component
 */
export default {
  page: {
    title: 'หน้าแรก',
    meta: [{ name: 'description' }],
  },
  components: { Layout },
  data() {
    var user = JSON.parse(localStorage.getItem('users'))
    // console.log(user)
    // if(user[0].status_flg != '1') {
    //   window.location.href = '/register/index'
    // }
    return {
      title: 'หน้าแรก',
      items: [],
      profile_name: user[0].name,
    }
  },
  methods:{
    routersClick(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
    },
  },
}
</script>

<template>
  <Layout />
</template>
<style>
.col-main-menu {
  margin-right:3%
}
.main-menu-big-btn {
  height: 200px;
  width: 240px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #d8dbdf;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 35px;
  padding: 15px;
}
.main-menu-big-btn:hover {
  transition: 0.3s;
  border: 1px solid #03AEEF;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.main-menu-big-btn-font {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 5%;
}
p {
  margin-bottom: 0px;
}
.main-menu-big-btn-font-sub {
  padding: 0px 15px 15px 15px;
}
/* .margin-img {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.margin-img.sm{
  margin-top: 5rem;
  margin-bottom: 3.9rem;
} */
  .page-content {
    margin: 50px 10% 0% 10%;
  }
</style>
